import { Offer } from './Offer';
import { User } from './User';

export class Call {
  public created: Date = new Date();
  public id?: string;
  public bookedDate?: Date;
  public offer?: Offer;
  public part?: User;
  public userIds: string[] = []; // All participants including the host
  public partMessage?: string;
  public partSwishNumber?: string;
  public status:
    | 'draft'
    | 'requested'
    | 'suggested'
    | 'booked'
    | 'processing'
    | 'unpaid'
    | 'paid'
    | 'done'
    | 'part_cancelled'
    | 'host_cancelled' = 'requested';
  public timeSuggestions: Date[] = [];
  public isArchived: boolean = false;
  public eventlog: CallEvent[] = [];

  static getPayer(call: Call): User | undefined {
    return call.part!;
  }

  static getPayee(call: Call): User | undefined {
    return call.offer?.host!;
  }

  static getExtraParticipantIds(call: Call): string[] {
    if (!call.userIds) call.userIds = [];
    return call.userIds.filter(id => id != call.part?.id && id != call.offer?.host?.id);
  }
}

export enum CallEventType {
  'comment',
  'system_comment',
  'cancelled_the_meeting',
  'suggested_a_time',
  'booked_a_time',
  'confirmed_the_meeting_time',
  'asked_for_a_change_of_time',
  'created_a_new_meeting',
  'went_to_the_meeting_room',
  'archived_the_meeting',
  'sent_a_meeting_request',
  'closed_the_call',
}

export class CallEvent {
  constructor(
    public senderUserId: string | null,
    public displayName: string | null | undefined,
    public order: number,
    public date: Date,
    public comment: string,
    public type: CallEventType
  ) {}
}
