import { IonContent, IonItem, IonList } from '@ionic/react';
import { Trans } from "@lingui/macro";
import React from 'react';

export const FrontPage = () => {

  return <>
    <IonContent fullscreen id='loginpage'>
      <IonList lines="none" style={{paddingTop: 50}}>
        <IonItem>
          <h2>Sunbisoft Meeting Platform</h2>
        </IonItem>
        <IonItem>
          <h3><Trans>Connect to the meeting room from the meeting page.</Trans></h3>
        </IonItem>
        <IonItem><span>Powered by <a href="https://sunbisoft.com" target={'_blank'}>Sunbisoft</a></span></IonItem>
      </IonList>
    </IonContent>
  </>
}