import { IonButton, IonContent, IonInput, IonItem, IonLabel, IonList } from '@ionic/react';
import { Trans } from '@lingui/macro';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Fire } from '../../ringeling/FBClient';

export default function VisitorPage() {
  const params = useParams<{ site: string, callId: string, visitorEmail: string }>();
  const history = useHistory();
  const [name, setName] = useState("")

  useEffect(() => {
    Fire.signout();
    Fire.currentSite = params.site;
  }, [])

  function next() {
    history.replace(`/${params.site}/${params.callId}?visitorEmail=${params.visitorEmail}&visitorName=${name}`);
  }

  return (
    <IonContent fullscreen id='loginpage'>
      <IonList lines='none' style={{ padding: '50px' }}>
        <IonItem>
          <h2>Welcome {params.visitorEmail}</h2>
        </IonItem>
        <IonItem>
          <IonLabel position='stacked'>Name:</IonLabel>
          <IonInput onIonChange={(e) => setName(e.detail.value ?? "")}></IonInput>
        </IonItem>
        <IonItem><IonButton onClick={next} size='default' disabled={name.length == 0}><Trans>Continue</Trans></IonButton></IonItem>
      </IonList>
    </IonContent>
  )
}