import { IonProgressBar, useIonAlert } from '@ionic/react';
import { t, Trans } from '@lingui/macro';
import { Button } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';
import { Fire } from '../../../ringeling/FBClient';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      background: theme.brand,
      color: 'white',
      '&:hover': {
        background: '#600101',
      },
    },
  })
);

export default function EndCallButton(props: { className?: string }) {
  const classes = useStyles();
  const { room } = useVideoContext();
  const [present] = useIonAlert();
  const [closeInProgress, setCloseInProgress] = useState(false);
  const [needLeaveWarning, setNeedLeaveWarning] = useState(true);

  const currentCall = Fire.currentCall;
  useEffect(() => {
    if (currentCall?.offer?.host?.id == Fire.user?.id) {
      window.onbeforeunload = () => {
        if (needLeaveWarning) {
          leave();
          return `Please close the meeting with the "Leave" button below.`;
        }
      };
    }
  });

  function leave() {
    setNeedLeaveWarning(false);
    if (Fire.isHostOfCurrentCall()) {
      present({
        header: t`Is the meeting over?`,
        buttons: [
          { text: t`Yes, close the meeting`, handler: () => disconnectAndGoback(true) },
          { text: t`No, it'll continue later`, handler: () => disconnectAndGoback(false) },
          { text: t`Cancel` },
        ],
      });
    } else {
      present({
        header: t`Leave the meeting?`,
        buttons: [{ text: t`Yes, leave now`, handler: () => disconnectAndGoback(false) }, { text: t`Cancel` }],
      });
    }
  }
  async function disconnectAndGoback(finishCall: boolean) {
    room!.disconnect();
    let callId = Fire.currentCall?.id;
    let site = Fire.currentCall?.offer?.site!;
    let s = site.includes('localhost') ? '' : 's';
    let visitorParam = (!Fire.isLoggedIn() && Fire.user?.email) ? `?visitor=${Fire.user?.email}` : '';
    if (visitorParam) {
      window.location.href = `http${s}://${site}/call/${callId}${visitorParam}`;
    } else
      window.location.href = `http${s}://${site}/call/${callId}${finishCall ? '?action=close' : ''}`;
  }

  return closeInProgress ? (
    <div>
      <IonProgressBar type="indeterminate" style={{ width: '100px' }} />
      <div>
        <Trans>Closing...</Trans>
      </div>
    </div>
  ) : (
    <Button onClick={leave} className={clsx(classes.button, props.className)} data-cy-disconnect>
      <Trans>Leave</Trans>
    </Button>
  );
}
