export const DEFAULT_VIDEO_CONSTRAINTS: MediaStreamConstraints['video'] = {
  width: 1280,
  height: 720,
  frameRate: 24,
};

// These are used to store the selected media devices in localStorage
export const SELECTED_AUDIO_INPUT_KEY = 'TwilioVideoApp-selectedAudioInput';
export const SELECTED_AUDIO_OUTPUT_KEY = 'TwilioVideoApp-selectedAudioOutput';
export const SELECTED_VIDEO_INPUT_KEY = 'TwilioVideoApp-selectedVideoInput';

// This is used to store the current background settings in localStorage
export const SELECTED_BACKGROUND_SETTINGS_KEY = 'TwilioVideoApp-selectedBackgroundSettings';
// export const ROOM_TYPE = 'go'; // 'peer-to-peer', 'go', 'group'
export const ROOM_TYPE_GO = 'go'; // 'peer-to-peer', 'go', 'group'
export const ROOM_TYPE_P2P = 'peer-to-peer'; // 'peer-to-peer', 'go', 'group'
export const ROOM_TYPE_GROUP = 'group'; // 'peer-to-peer', 'go', 'group'
