import { i18n } from '@lingui/core';
import 'firebase/analytics';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';
import { Billable } from '../duplicated/Billable';
import { Call } from '../duplicated/Call';
import { User } from '../duplicated/User';
import { DEFAULT_LANGUAGE } from '../locales/translation';
import { officeMentorAsiafirebaseConf, sunlineEUfirebaseConfig, sunlineUSfirebaseConfig } from './FirebaseConfigs';

const settings: { name: string, setting: any, region: string }[] = [
  { name: 'officementorasia', setting: officeMentorAsiafirebaseConf, region: 'asia-northeast1' },
  { name: 'sunline-eu', setting: sunlineEUfirebaseConfig, region: 'europe-west3' },
  { name: 'sunline-us', setting: sunlineUSfirebaseConfig, region: 'us-central1' },
]

const firebaseConfig = settings.find(s => s.name == process.env.REACT_APP_SERVER_ID)!;

if (!firebaseConfig) {
  alert("NO FIREBASE CONFIG");
  console.error("NO FIREBASE CONFIG")
}

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig.setting);
}

export class FBClient {
  user?: User;
  store = firebase.firestore();
  funcs = firebase.app().functions(firebaseConfig.region);
  currentCall?: Call;
  currentCallUsers: User[] = [];
  currentSite?: string;

  siteDoc(): firebase.firestore.DocumentReference<firebase.firestore.DocumentData> {
    if (!this.currentSite)
      throw new Error("No current site set")
    return this.store.collection('sites').doc(this.currentSite);
  }

  setUserAsVisitor(name: string, email: string) {
    this.user = new User("", name, email, null, null, null);
  }

  setAuthChangedHandler(handler: (fbUser: firebase.User | null) => void) {
    return firebase.auth().onAuthStateChanged(async user => {
      if (user?.uid && this.currentSite) {
        let doc = await this.siteDoc().collection('siteusers').doc(user.uid).get();
        this.user = doc.data() as User;

        if (this.user && !!this.user.lang && this.user.lang != DEFAULT_LANGUAGE) {
          i18n.activate(this.user.lang);
        }
      } else {
        this.user = undefined;
      }
      handler(user);
    });
  }

  async signInWithCustomToken(firebaseToken: string, done: (success: boolean) => void) {
    if (firebase.auth().currentUser != null) {
      await firebase.auth().signOut();
    }
    try {
      let userCredential = await firebase.auth().signInWithCustomToken(firebaseToken);

      done(!!userCredential.user?.uid);
    } catch (error) {
      console.error(error);

      done(false);
    }
  }

  isLoggedIn() {
    return firebase.auth().currentUser != null;
  }
  async signout() {
    console.log('Signed out');
    await firebase.auth().signOut();
  }

  async loadCall(callId: string): Promise<Call | null> {
    console.log('Called FBClient.loadCall');

    let doc = await this.siteDoc().collection('sitecalls').doc(callId).get();
    if (doc.exists) {
      this.currentCall = doc.data() as Call;

      if (this.currentCall.part)
        this.currentCallUsers.push(this.currentCall.part!);
      if (this.currentCall.offer?.host)
        this.currentCallUsers.push(this.currentCall.offer?.host);

      let extraUserIds = Call.getExtraParticipantIds(this.currentCall);
      if (extraUserIds.length > 0) {
        let q = await this.siteDoc().collection('siteusers').where('id', 'in', extraUserIds).get();
        q.docs.map(doc => doc.data() as User).forEach(u => this.currentCallUsers.push(u));
      }
      return this.currentCall;
    } else {
      return null;
    }
  }

  async addBillable(bill: Billable): Promise<void> {
    console.log('Adding a billable');

    await this.siteDoc().collection('sitecalls').doc(bill.callId).collection('billables').doc(bill.id).set(FBClient.toPOJO(bill));
  }

  async makeRoomAndGetTwilioToken(email: string, roomType: string): Promise<any> {
    const func = this.funcs!.httpsCallable('makeRoomAndGetTwilioToken');
    console.info('room type is ' + roomType);
    if (this.currentCall?.id && this.currentSite) {
      let resp: any = await func({
        isVisitor: !this.isLoggedIn(),
        email: email,
        siteId: this.currentSite,
        callId: this.currentCall.id,
        roomType: roomType, // 'peer-to-peer', 'go', 'group'
      });
      return resp.data;
    }
  }

  subscribeToBillables(callId: string, handler: (nrMinutes: number) => void) {
    return this.siteDoc().collection('sitecalls').doc(callId).collection('billables')
      .onSnapshot(docs => {
        handler(docs.size);
      });
  }

  isHostOfCurrentCall(): boolean {
    return this.currentCall?.offer?.host?.id === Fire.user?.id;
  }

  static toPOJO(obj: any) {
    return JSON.parse(JSON.stringify(obj));
  }
}

export const Fire = new FBClient();
