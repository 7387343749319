import { Fullscreen, FullscreenExit } from '@material-ui/icons';
import React, { useState } from 'react';

export default function FullscreenToggle() {
  const [isFullscreen, setIsFullscreen] = useState(false);

  function toggleFullscreen() {
    let elements = document.querySelectorAll<HTMLElement>('.hidable')!;
    let main = document.getElementById('main') as HTMLElement;
    elements.forEach(element => {
      if (isFullscreen) {
        element.classList.remove('hidden');
        main.classList.remove('no-padding');
      } else {
        element.classList.add('hidden');
        main.classList.add('no-padding');
      }
    });
    setIsFullscreen(!isFullscreen);
  }

  return (
    <div id="fullscreen-toggle" onClick={toggleFullscreen}>
      {isFullscreen ? <FullscreenExit /> : <Fullscreen />}
    </div>
  );
}
