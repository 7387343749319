import { i18n } from '@lingui/core';
import { messages as enMessages } from './en/messages';
import { messages as svMessages } from './sv/messages';
import { messages as koMessages } from './ko/messages';
import { messages as jaMessages } from './ja/messages';
import { en, sv, ko, ja } from 'make-plural/plurals';
import { detect, fromUrl, fromStorage, fromNavigator } from '@lingui/detect-locale';

i18n.loadLocaleData({
  en: { plurals: en },
  sv: { plurals: sv },
  ko: { plurals: ko },
  ja: { plurals: ja },
});

i18n.load({
  en: enMessages,
  sv: svMessages,
  ko: koMessages,
  ja: jaMessages,
});

export const DEFAULT_LANGUAGE =
  detect(
    fromUrl('lang'),
    fromStorage('lang'),
    fromNavigator(),
    () => 'en' // default language
  )?.substring(0, 2) ?? 'en';
