import { IonProgressBar } from "@ionic/react";
import { i18n } from "@lingui/core";
import { I18nProvider } from '@lingui/react';
import { CssBaseline } from '@material-ui/core';
import { MuiThemeProvider } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Switch, useHistory, useParams } from 'react-router-dom';
import App from './App';
import { ChatProvider } from './components/ChatProvider';
import ErrorDialog from './components/ErrorDialog/ErrorDialog';
import LoginPage from "./components/LoginPage/LoginPage";
import VisitorPage from "./components/LoginPage/VisitorPage";
import UnsupportedBrowserWarning from './components/UnsupportedBrowserWarning/UnsupportedBrowserWarning';
import { VideoProvider } from './components/VideoProvider';
import { FrontPage } from "./FrontPage";
import { DEFAULT_LANGUAGE } from "./locales/translation";
import CallLogFrame from './ringeling/CallLogFrame';
import { Fire } from "./ringeling/FBClient";
import AppStateProvider, { useAppState } from './state';
import './style.scss';
import theme from './theme';
import './types';
import { AppUtil } from "./utils/AppUtil";
import useConnectionOptions from './utils/useConnectionOptions/useConnectionOptions';

i18n.activate(DEFAULT_LANGUAGE);

const VideoApp = () => {
  const { error, setError } = useAppState();
  const connectionOptions = useConnectionOptions();
  const [isReady, setIsReady] = useState(false);
  const params = useParams<{ site: string, callId: string }>();
  const history = useHistory();

  useEffect(() => {
    console.log('params.site is', params.site);

    // Fire.setUserAsVisitor(name, params.visitorEmail);
    Fire.currentSite = params.site;
    return Fire.setAuthChangedHandler(user => {
      console.log('Auth changed. User is ', user?.email);
      // setLoggedIn(!!user);
      if (!user) {
        let visitorName = AppUtil.getParam('visitorName', history);
        let visitorEmail = AppUtil.getParam('visitorEmail', history);
        if (visitorEmail && visitorName) {
          Fire.setUserAsVisitor(visitorName, visitorEmail);
          console.log('Visitor', visitorName);
        }
      }

      Fire.loadCall(params.callId).then(call => {
        setIsReady(!!call);
      });
    });
  }, [])

  return (
    <div id="mainframe">
      {Fire.isLoggedIn() && <CallLogFrame />}
      <VideoProvider options={connectionOptions} onError={setError}>
        <ErrorDialog dismissError={() => setError(null)} error={error} />
        <ChatProvider>
          {isReady && <App />}
          {!isReady && <IonProgressBar type='indeterminate' />}
        </ChatProvider>
      </VideoProvider>
    </div>
  );
};

ReactDOM.render(
  <MuiThemeProvider theme={theme}>
    <CssBaseline />
    <UnsupportedBrowserWarning>
      <Router>
        <I18nProvider i18n={i18n}>
          <AppStateProvider>
            <Switch>
              <Route exact path="/">
                <FrontPage/>
              </Route>
              <Route exact path="/:site/:callId/:token">
                <LoginPage />
              </Route>
              <Route exact path="/:site/:callId/visitor/:visitorEmail">
                <VisitorPage />
              </Route>
              <Route exact path="/:site/:callId">
                <VideoApp />
              </Route>
            </Switch>
          </AppStateProvider>
        </I18nProvider>
      </Router>
    </UnsupportedBrowserWarning>
  </MuiThemeProvider>,
  document.getElementById('root')
);

