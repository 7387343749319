import { IonContent, IonItem, IonList, IonProgressBar } from '@ionic/react';
import { t } from '@lingui/macro';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Fire } from '../../ringeling/FBClient';

export default function LoginPage() {
  const params = useParams<{ site: string, callId: string, token: string }>();
  const history = useHistory();
  const [status, setStatus] = useState("")

  useEffect(() => {
    setStatus(t`Authenticating...`);
    Fire.currentSite = params.site;
    Fire.signInWithCustomToken(params.token, success => {
      if (success) {
        setStatus("OK");
        history.replace(`/${params.site}/${params.callId}`);
      } else {
        setStatus(t`Failed to authenticate. Go back and try again.`);
      }
    });
  }, [])

  return (
    <IonContent fullscreen id='loginpage'>
      <IonList lines='none' style={{ padding: '50px' }}>
        <IonItem>
          <h2>{status}</h2>
        </IonItem>
        <IonItem>
          <IonProgressBar type='indeterminate' />
        </IonItem>
      </IonList>
    </IonContent>
  )
}