import { t } from '@lingui/macro';

export class DateUtil {

  static DAY_MILLISECONDS = 24 * 60 * 60 * 1000;

  static dateStrToTimeAndDay(dateString: any, locale:string): string {
    let date = new Date(dateString);
    return t`${date.toTimeString().substr(0, 5)}, ${this.dateStrToDay(dateString, locale)}`;
  }

  static dateStrToDay(dateString: any, locale:string): string {
    if (!Boolean(dateString)) {
      return t`(no date)`;
    }
    let date = new Date(dateString);
    let today = new Date();
    let day = date.toDateString();
    if (day == today.toDateString()) {
      return t`today`;
    } else if (day == new Date(today.getTime() + this.DAY_MILLISECONDS).toDateString()) {
      return t`tomorrow`;
    } else
      return date.toLocaleDateString(locale);
  }
}
