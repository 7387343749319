export const sunlineUSfirebaseConfig = {
  apiKey: "AIzaSyAMfSBQDKObbPYMXuGBzqK2DBzrmrp0yiQ",
  authDomain: "sunline-us.firebaseapp.com",
  projectId: "sunline-us",
  storageBucket: "sunline-us.appspot.com",
  messagingSenderId: "892215003500",
  appId: "1:892215003500:web:f5c09200d9d387005f4b49",
  measurementId: "G-5JSLC62820"
};


export const sunlineEUfirebaseConfig = {
  apiKey: "AIzaSyAUyxgkhmej3IYd-FcpwXZ_kNinMzPODVE",
  authDomain: "eu.sunbisoft.com",
  // authDomain: "sunline-eu.firebaseapp.com",
  projectId: "sunline-eu",
  storageBucket: "sunline-eu.appspot.com",
  messagingSenderId: "738327816398",
  appId: "1:738327816398:web:fcc61df13d63b1c140c89f",
  measurementId: "G-1VQ48PPWH8"
};

export const officeMentorAsiafirebaseConf = {
  apiKey: "AIzaSyD974CtvmFB_2UVdObldH_C0CZ6zUrTo4Q",
  authDomain: "officementor.net",
  projectId: "officementorasia",
  storageBucket: "officementorasia.appspot.com",
  messagingSenderId: "365017279909",
  appId: "1:365017279909:web:6c50f4a86158a51a4b4c4c",
  measurementId: "G-LFVTW1RED0"
};

