//sites/siteusers
export class User {
  static COL = 'siteusers';
  public accessKey?: string;
  public lang?: string; // 2 chars
  public timezone?: string;

  public title?: string;
  public company?: string;
  public companyURL?: string;
  public linkedinURL?: string;
  public introduction?: string;

  public categories?: string[];
  public isListed: boolean = false;
  public rank?: number = 0; // set by admin/system
  public isVisitor = false;

  constructor(
    public id: string,
    public displayName: string | null,
    public email: string | null,
    public username: string | null,
    public photoURL: string | null,
    public providerId: string | null,
  ) {
  }
}

export class CreateUserDTO {
  constructor(public siteId: string, public user: User, public password: string) {
  }
}

